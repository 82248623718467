import React from "react";
import { useTranslation } from "react-i18next";

interface BlockIntegrationProps {
    handleCloseModal: (e: any, closed: boolean) => void;
}

export const BlockIntegration = ({ handleCloseModal }: BlockIntegrationProps) => {
    const { t } = useTranslation();

    return (
        <div className="h-100 d-flex flex-column align-items-center justify-content-center position-relative p-3">
            <span onClick={(e: any) => handleCloseModal(e, true)} className="modal-lateral-close position-absolute" style={{ width: '12px', height: '12px', right: '1rem', top: '1rem' }}></span>
            <div className="d-flex flex-column justify-content-center align-items-center text-center px-3">
                <img className="w-50" src="../img/upgrade-scheme.png" alt=" " />
                <h1 className="font-20px text-dark-100 font-sfpro-medium">{ t('block-integration-title') }</h1>
                <h2 className="font-16px text-dark-100 font-sfpro-regular" dangerouslySetInnerHTML={{ __html: t('block-integration-subtitle') }}></h2>

            </div>
        </div>
    )
}